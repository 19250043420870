import fetcher from "../helpers/fetcher";

export async function fetchPartners() {
  let path = "/loops/partners";
  let response = await fetcher(path, "GET");
  let jsonResponse = await response.json();
  return jsonResponse;
}

export async function fetchCounties() {
  let path = "/loops/counties";
  let response = await fetcher(path, "GET");
  let jsonResponse = await response.json();
  return jsonResponse;
}

export async function fetchCities() {
  let path = "/loops/cities";
  let response = await fetcher(path, "GET");
  let jsonResponse = await response.json();
  return jsonResponse;
}

export async function fetchLocations() {
  let path = "/loops/locations";
  let response = await fetcher(path, "GET");
  let jsonResponse = await response.json();
  return jsonResponse;
}

export async function fetchTags() {
  let path = "/loops/tags";
  let response = await fetcher(path, "GET");
  let jsonResponse = await response.json();
  return jsonResponse;
}

export async function fetchDevices(filters, page = 1) {
  const queryParams = new URLSearchParams();

  if (filters) {
    for (const [key, value] of Object.entries(filters)) {
      if (key === "partner" && value) {
        queryParams.append(key, value);
      } else if (Array.isArray(value) && value.length > 0) {
        value.forEach((item) => queryParams.append(key, item));
      } else if (key === "search" && value) {
        // Directly append if search exists
        queryParams.append("search", value);
      }
    }
  }

  queryParams.append("page", page);

  const path = `/loops/devices?${queryParams.toString()}`;
  const response = await fetcher(path, "GET");
  const jsonResponse = await response.json();
  return jsonResponse;
}

export async function generateLoop(body) {
  let path = "/loops/generate-loop";
  let response = await fetcher(path, "POST", body);
  let jsonResponse = await response.json();
  return jsonResponse;
}

// export async function downloadLoop(loop_id) {
//   let path = `/loops/${loop_id}/download`;

//   let request = await fetcher(path, "GET", null, false, true);

//   let content = await request.blob();

//   let contentDisposition = request.headers.get("content-disposition");

//   let filename = contentDisposition.split("filename=")[1];
//   if (!filename) {
//     throw new Error("Filename is missing in Content-Disposition header");
//   }

//   filename = filename.replace(/"/g, "");

//   return { content, filename };
// }

export async function downloadLoop(loop_id) {
  const path = `/loops/${loop_id}/download`;

  const response = await fetcher(path, "GET", null, false, true);

  if (!response.ok) {
    throw new Error("Failed to fetch SAS URL");
  }

  const data = await response.json();

  if (!data.url) {
    throw new Error("SAS URL is missing in response");
  }

  return { url: data.url };
}

export async function getAllLoops(page, debouncedSearchTerm = "") {
  let path = `/loops?page=${page}&search=${debouncedSearchTerm}`;
  let response = await fetcher(path, "GET");
  let jsonResponse = await response.json();
  return jsonResponse;
}

export async function deleteLoop(loop_id) {
  let path = `/loops/${loop_id}`;
  let response = await fetcher(path, "DELETE");
  let jsonResponse = await response.json();
  return jsonResponse;
}

export async function publishLoop(loop_id, selectedDevices) {
  const body = {
    loop_id: loop_id,
    devices: selectedDevices,
  };
  let path = "/loops/publish";
  let response = await fetcher(path, "PUT", JSON.stringify(body));
  let jsonResponse = await response.json();
  return jsonResponse;
}

export async function getLoopDetails(loop_id) {
  let path = `/loops/${loop_id}/details`;
  let response = await fetcher(path, "GET");
  let jsonResponse = await response.json();
  return jsonResponse;
}

export async function fetchVideosByCategories(payload) {
  try {
    const queryParams = new URLSearchParams();

    if (payload.partner) {
      queryParams.append("partner", payload.partner);
    }

    if (payload.counties && payload.counties.length > 0) {
      payload.counties.forEach((county) =>
        queryParams.append("counties", county)
      );
    }

    if (payload.cities && payload.cities.length > 0) {
      payload.cities.forEach((city) => queryParams.append("cities", city));
    }

    if (payload.locations && payload.locations.length > 0) {
      payload.locations.forEach((location) =>
        queryParams.append("locations", location)
      );
    }

    if (payload.tags && payload.tags.length > 0) {
      payload.tags.forEach((tag) => queryParams.append("tags", tag));
    }

    const response = await fetcher(
      `/loops/videos?${queryParams.toString()}`,
      "GET"
    );
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.error("Error fetching videos by categories:", error);
    throw error;
  }
}

export async function confirmLoop(timeInterval, finalPlaylist) {
  const path = `/loops/process-loop?time_interval=${timeInterval}`;

  const response = await fetcher(path, "POST", JSON.stringify(finalPlaylist));

  const jsonResponse = await response.json();

  return jsonResponse;
}

export async function submitEditedLoop(body, id, forceProceed) {
  const path = `/loops/edit/${id}?force_proceed=${forceProceed}`;

  const response = await fetcher(path, "POST", JSON.stringify(body));

  const jsonResponse = await response.json();

  return jsonResponse;
}
