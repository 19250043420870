import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertModal({
  text,
  open,
  handleClose,
  handleYes,
  showYesButton = true,
  showNoButton = true,
  showOkButton = false,
  childrenStyle = {},
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={childrenStyle} id="alert-dialog-title">
          <span style={{ fontFamily: "SeagoeUI" }}>{text}</span>
        </DialogTitle>
        <DialogActions>
          {showYesButton && (
            <button className="add-video-button" onClick={handleYes}>
              Yes
            </button>
          )}
          {showNoButton && (
            <button className="add-video-button" onClick={handleClose}>
              No
            </button>
          )}
          {showOkButton && (
            <button
              className="add-video-button"
              onClick={handleClose}
              autoFocus
            >
              Ok
            </button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
