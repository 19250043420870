import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import {
  confirmLoop,
  deleteLoop,
  fetchVideosByCategories,
  generateLoop,
} from "../../services/loop";
import { DownloadVideo } from "../../services/video";
import { Monitor, Play, Sliders } from "react-feather";
import { Chip } from "@mui/material";
import PopSnackbar from "../../components/snackbar";
import AlertModal from "../../components/modal";

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
    "& .MuiInputLabel-root": { color: "#555" },
    "&.Mui-disabled .MuiOutlinedInput-root": { backgroundColor: "#f0f0f0" },
  },
};

const LoopPreview = () => {
  const location = useLocation();
  const { partner, counties, cities, locations, tags, timeInterval } =
    location.state;

  const [loadingVideos, setLoadingVideos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [toastStatus, setToastStatus] = useState(false);
  const [toastDescription, setToastDescription] = useState("");
  const [data, setData] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const navigate = useNavigate();

  const fetchAllVideos = async () => {
    setLoadingVideos(true);
    try {
      const payload = {
        partner: partner.id,
        counties,
        cities,
        locations: locations.map((loc) => ({
          id: loc.id,
          name: loc.name,
        })),
        tags: tags.map((tag) => ({
          id: tag.id,
          name: tag.name,
        })),
      };
      const response = await fetchVideosByCategories(payload);
      setVideos(response.data);
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setLoadingVideos(false);
    }
  };

  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  const handleVideoSelection = async (newSelectedVideos) => {
    const existingPreviewsMap = new Map(
      videoPreviews.map((video) => [video.id, video])
    );

    const newPreviews = [];
    for (const video of newSelectedVideos) {
      try {
        if (existingPreviewsMap.has(video.ID)) {
          newPreviews.push(existingPreviewsMap.get(video.ID));
        } else {
          const { content } = await DownloadVideo(video.ID);
          const previewURL = URL.createObjectURL(content);
          newPreviews.push({
            id: video.ID,
            title: video.title,
            duration: video.duration,
            previewURL,
            minDisplays: 1, // Default display limit for new videos
          });
        }
      } catch (error) {
        console.error(`Error downloading video ${video.title}:`, error);
      }
    }

    setVideoPreviews(newPreviews);
    setSelectedVideos(newSelectedVideos);
  };

  const handleMinDisplayTimeChange = (id, newValue) => {
    setVideoPreviews((prevPreviews) =>
      prevPreviews.map((video) =>
        video.id === id
          ? { ...video, minDisplays: parseInt(newValue, 10) || 0 }
          : video
      )
    );
  };

  const generateLoopPayload = async () => {
    const loopPayload = {
      partner: partner.id,
      counties,
      cities,
      locations,
      tags,
      timeInterval,
      videoDisplays: videoPreviews.map((video) => ({
        id: video.id,
        minDisplays: video.minDisplays,
      })),
    };

    try {
      setLoading(true);
      const response = await generateLoop(JSON.stringify(loopPayload));
      console.log(response);
      if (response.status === "success") {
        setData(response);
      } else {
        throw new Error(response.message);
      }
      handleStatus(
        "Generated loop successfully",
        setToastStatus,
        setToastDescription
      );
    } catch (error) {
      handleStatus(
        error.message || "Something went wrong",
        setToastStatus,
        setToastDescription
      );
    } finally {
      setLoading(false);
    }
  };

  const confirmLoopGeneration = () => {
    try {
      setLoading(true);

      const time_interval = data.timeInterval;

      confirmLoop(time_interval, data.finalPlaylist);
    } catch (error) {
      console.error("Error confirming loop generation:", error);
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };

  useEffect(() => {
    fetchAllVideos();
  }, []);

  return (
    <div>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "flex-start",
          }}
        >
          <div style={{ flex: 2 }}>
            <div style={{ marginBottom: "20px" }}>
              <Card
                style={{
                  padding: "15px",
                  boxShadow: "0 4px 12px #0000001a",
                  borderRadius: "12px",
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "8px",
                      marginBottom: "10px",
                    }}
                  >
                    <Play size={22} />
                    <label
                      style={{
                        fontSize: "1.5rem",
                        fontFamily: "SeagoeUIBold",
                      }}
                    >
                      Manually Select Videos
                    </label>
                  </div>
                  {loadingVideos ? (
                    <CircularProgress size={20} color="black" />
                  ) : (
                    <Autocomplete
                      multiple
                      options={videos.filter(
                        (video) =>
                          !selectedVideos.some(
                            (selected) => selected.ID === video.ID
                          )
                      )}
                      getOptionLabel={(option) => option.title}
                      value={selectedVideos}
                      onChange={(event, newValue) =>
                        handleVideoSelection(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          sx={styles.select}
                          {...params}
                          placeholder="Choose videos"
                          fullWidth
                        />
                      )}
                    />
                  )}
                </CardContent>
              </Card>
            </div>

            <Card
              style={{
                padding: "15px",
                boxShadow: "0 4px 12px #0000001a",
                borderRadius: "12px",
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "8px",
                    marginBottom: "10px",
                  }}
                >
                  <Monitor size={22} />
                  <label
                    style={{
                      fontSize: "1.5rem",
                      fontFamily: "SeagoeUIBold",
                    }}
                  >
                    Selected Videos with Minimum Number of Displays
                  </label>
                </div>
                <Divider style={{ margin: "10px 0" }} />
                {videoPreviews.map((video) => (
                  <div
                    key={video.id}
                    style={{
                      marginBottom: "15px",
                      padding: "10px",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                    }}
                  >
                    <div style={{ display: "flex", gap: "15px" }}>
                      <video
                        src={video.previewURL}
                        controls
                        style={{
                          height: "100px",
                        }}
                      ></video>
                      <div style={{ flex: 1 }}>
                        <h4 style={{ fontFamily: "SeagoeUIBold" }}>
                          {video.title}
                        </h4>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <label style={{ fontSize: "14px" }}>
                            Minimum number of displays:
                          </label>
                          <TextField
                            sx={styles.select}
                            type="text"
                            value={video.minDisplays}
                            onChange={(e) => {
                              const input = e.target.value;
                              const parsedValue =
                                input === ""
                                  ? ""
                                  : Math.max(0, parseInt(input, 10) || 0);
                              handleMinDisplayTimeChange(video.id, parsedValue);
                            }}
                            onWheel={(e) => {
                              if (e.target.value !== "") {
                                const currentValue =
                                  parseInt(e.target.value, 10) || 0;
                                const newValue =
                                  e.deltaY < 0
                                    ? currentValue + 1
                                    : currentValue - 1;
                                const parsedValue = Math.max(0, newValue);
                                handleMinDisplayTimeChange(
                                  video.id,
                                  parsedValue
                                );
                              }
                            }}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            variant="outlined"
                            size="small"
                            style={{
                              width: "60px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </CardContent>
            </Card>
            <button
              style={{
                marginTop: "20px",
                borderRadius: "12px",
                marginBottom: "20px",
                opacity: loading || loadingVideos ? 0.5 : 1,
              }}
              className="add-video-button"
              disabled={loading || loadingVideos}
              onClick={() => {
                generateLoopPayload();
                setOpenConfirmModal(true);
              }}
            >
              Generate Loop
            </button>

            {!(Array.isArray(data) && data.length === 0) && (
              <AlertModal
                open={openConfirmModal}
                text={
                  <>
                    <p>The percentages for each category are as follows:</p>
                    <p>
                      <span style={{ fontFamily: "SeagoeUIBold" }}>
                        {data.percentage1?.toFixed(2)}%
                      </span>{" "}
                      for campanii publicitare,
                    </p>
                    <p>
                      <span style={{ fontFamily: "SeagoeUIBold" }}>
                        {data.percentage2?.toFixed(2)}%
                      </span>{" "}
                      for spoturi de locatie,
                    </p>
                    <p>
                      <span style={{ fontFamily: "SeagoeUIBold" }}>
                        {data.percentage3?.toFixed(2)}%
                      </span>{" "}
                      for continut informativ,
                    </p>
                    <p>Do you want to continue?</p>
                  </>
                }
                handleYes={confirmLoopGeneration}
                handleClose={() => setOpenConfirmModal(false)}
              />
            )}
          </div>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Card
              style={{
                padding: "15px",
                boxShadow: "0 4px 12px #0000001a",
                borderRadius: "12px",
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <Sliders size={24} />
                  <label
                    style={{
                      fontSize: "1.75rem",
                      fontFamily: "SeagoeUIBold",
                      color: "#333",
                    }}
                  >
                    Configuration Details
                  </label>
                </div>
                <Divider
                  style={{ margin: "15px 0", backgroundColor: "#e0e0e0" }}
                />

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ fontFamily: "SeagoeUIBold", color: "#555" }}>
                    Partner
                  </label>
                  <div
                    style={{ display: "flex", gap: "8px", marginTop: "8px" }}
                  >
                    {partner?.name ? (
                      <Chip
                        label={partner.name}
                        style={{ backgroundColor: "#e0f7fa" }}
                      />
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ fontFamily: "SeagoeUIBold", color: "#555" }}>
                    Counties
                  </label>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                      marginTop: "8px",
                    }}
                  >
                    {counties.length > 0 ? (
                      counties.map((county, index) => (
                        <Chip
                          key={index}
                          label={county}
                          style={{
                            backgroundColor: "#e0f7fa",
                          }}
                        />
                      ))
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ fontFamily: "SeagoeUIBold", color: "#555" }}>
                    Cities
                  </label>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                      marginTop: "8px",
                    }}
                  >
                    {cities.length > 0 ? (
                      cities.map((city, index) => (
                        <Chip
                          key={index}
                          label={city}
                          style={{
                            backgroundColor: "#e0f7fa",
                          }}
                        />
                      ))
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ fontFamily: "SeagoeUIBold", color: "#555" }}>
                    Locations
                  </label>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                      marginTop: "8px",
                    }}
                  >
                    {locations.length > 0 ? (
                      locations.map((loc, index) => (
                        <Chip
                          key={index}
                          label={loc.name}
                          style={{
                            backgroundColor: "#e0f7fa",
                          }}
                        />
                      ))
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ fontFamily: "SeagoeUIBold", color: "#555" }}>
                    Tags
                  </label>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                      marginTop: "8px",
                    }}
                  >
                    {tags.length > 0 ? (
                      tags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag.name}
                          style={{
                            backgroundColor: "#e0f7fa",
                          }}
                        />
                      ))
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>

                <div>
                  <label style={{ fontFamily: "SeagoeUIBold", color: "#555" }}>
                    Time Interval Selected
                  </label>
                  <div
                    style={{ display: "flex", gap: "8px", marginTop: "8px" }}
                  >
                    {timeInterval ? (
                      <Chip
                        label={`${timeInterval} minutes`}
                        style={{ backgroundColor: "#e0f7fa" }}
                      />
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      )}
      {toastStatus && <PopSnackbar message={toastDescription} />}
    </div>
  );
};

export default LoopPreview;
