import React, { useState, useEffect } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Modal,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Plus, Minus, Command } from "react-feather";

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
    "& .MuiInputLabel-root": { color: "#555" },
    "&.Mui-disabled .MuiOutlinedInput-root": { backgroundColor: "#f0f0f0" },
  },
};

export default function TagVideoTab({
  availableTags,
  tags,
  setTags,
  handleAddTag,
  preview,
  isTagsLoading,
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState([]); // Local state for modal session

  // Synchronize modal state with parent tags
  useEffect(() => {
    const matchingTags = availableTags.filter((tag) =>
      tags.some((t) => t.ID === tag.ID)
    );
    setSelectedTags(matchingTags);
  }, [tags, availableTags]);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleSearchChange = (e) => setSearchTerm(e.target.value.toLowerCase());

  const filteredTags = availableTags.filter((tag) =>
    tag.name.toLowerCase().includes(searchTerm)
  );

  const handleTagSelection = (tag) => {
    if (selectedTags.some((t) => t.ID === tag.ID)) {
      setSelectedTags(selectedTags.filter((t) => t.ID !== tag.ID));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const toggleSelectAll = () => {
    if (selectedTags.length === availableTags.length) {
      setSelectedTags([]);
    } else {
      setSelectedTags([...availableTags]);
    }
  };

  const saveTags = () => {
    setTags(selectedTags); // Update the parent state on save
    toggleModal();
  };

  const handleRemoveTag = (index) => {
    const updatedTags = tags.filter((_, i) => i !== index);
    setTags(updatedTags); // Update the parent tags state
    setSelectedTags(updatedTags); // Sync the modal's state
  };

  return (
    <>
      <h1 className="add-video-title">Select Tags</h1>
      <Divider style={{ marginBottom: "20px" }} />
      <div
        style={{
          display: "flex",
          columnGap: "7px",
          alignItems: "center",
        }}
      >
        <Command fontSize={"1.25rem"} />
        <span style={{ fontSize: "1.25rem" }}>Video tags</span>
        <IconButton onClick={toggleModal} disabled={preview}>
          <Plus />
        </IconButton>
      </div>
      {isTagsLoading ? (
        <CircularProgress style={{ alignSelf: "center" }} />
      ) : (
        <List>
          {tags.map((tag, index) => (
            <ListItem
              key={tag.ID || index}
              style={{ display: "flex", alignItems: "center" }}
            >
              <ListItemText primary={tag.name} />
              <IconButton
                onClick={() => handleRemoveTag(index)}
                disabled={preview}
              >
                <Minus />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}
      {/* Modal for selecting tags */}
      <Modal open={isModalOpen} onClose={toggleModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            // maxHeight: "80vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <h2 style={{ fontFamily: "SeagoeUIBold" }}>Select Tags</h2>
          {/* Search Bar */}
          <TextField
            sx={styles.select}
            fullWidth
            placeholder="Search tags"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ marginBottom: "16px" }}
          />

          {/* Select/Deselect All Button */}
          <button
            className="add-video-button"
            onClick={toggleSelectAll}
            style={{ marginBottom: "16px", width: "100%" }}
          >
            {selectedTags.length === availableTags.length
              ? "Deselect All"
              : "Select All"}
          </button>

          {/* Scrollable Tag List */}
          <Box
            sx={{
              maxHeight: "50vh",
              overflowY: "scroll",
              marginBottom: "16px",
              border: "1px solid #ddd",
            }}
          >
            <List>
              {filteredTags.map((tag) => (
                <ListItem key={tag.ID}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedTags.some((t) => t.ID === tag.ID)}
                        onChange={() => handleTagSelection(tag)}
                      />
                    }
                    label={tag.name}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          {/* Action Buttons */}
          <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
            <button className="add-video-button" onClick={toggleModal}>
              Cancel
            </button>
            <button className="add-video-button" onClick={saveTags}>
              Save
            </button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
