import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  FindParentLocations,
  GetLocationInfo,
  SaveLocationInfo,
} from "../../services/locations";
import { TextFormField } from "../../components/textform/TextFormField.tsx";
import { AutoComplete } from "../../components/autocomplete/autocomplete.tsx";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import {
  MapPin,
  Video,
  HardDrive,
  Edit2,
  Eye,
  PlusSquare,
  MinusSquare,
} from "react-feather";
import {
  GoogleMap,
  useLoadScript,
  Autocomplete as GoogleAutocomplete,
  Marker,
} from "@react-google-maps/api";
import PopSnackbar from "../../components/snackbar/index.js";
import { Tooltip, MenuItem, ListItemText, Backdrop } from "@mui/material";
import {
  AddVideoToLocation,
  DownloadVideo,
  UploadVideo,
} from "../../services/video.js";
import { VideoPreview } from "../../components/modal/videopreview.js";
import AddVideoDialog from "../../components/modal/videodialog.js";
import MultiSelectFormField from "../../components/multiselectform/MultiSelectFormField.tsx";
import { GetAllDevices } from "../../services/devices.js";
import { GetTags } from "../../services/tag.js";

const libraries = ["places"];

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
    "& .MuiInputLabel-root": { color: "#555" },
    "&.Mui-disabled .MuiOutlinedInput-root": { backgroundColor: "#f0f0f0" },
  },
};

export const validation = yup.object().shape({
  name: yup.string().required("Location name is required"),
});

export default function EditLocation() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [preview, setPreview] = useState(false);
  const [toastStatus, setToastStatus] = useState(false);
  const [toastDescription, setToastDescription] = useState("");
  const [center, setCenter] = useState({ lat: 44.4268, lng: 26.1025 });
  const [markerPosition, setMarkerPosition] = useState(center);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [loadingPreview, setLoadingPreview] = useState(false);

  const { id } = useParams();
  const { state } = useLocation();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const autocompleteRef = useRef(null);

  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  async function GetDataInfo(idParam) {
    try {
      setLoading(true);
      const response = await GetLocationInfo(idParam);
      if (response.status !== "OK") {
        throw new Error(
          response.error || "Something went wrong, please try again later."
        );
      }
      setData(response.data);

      const responseDevices = await GetAllDevices();
      if (responseDevices.status !== "OK") {
        throw new Error(
          responseDevices.error ||
            "Something went wrong, please try again later."
        );
      }

      setDeviceData(responseDevices.data);

      const responseTags = await GetTags();
      if (responseTags.status !== "OK") {
        throw new Error(
          responseTags.error || "Something went wrong, please try again later."
        );
      }

      setTagData(responseTags.data);
    } catch (error) {
      handleStatus(error.message, setToastStatus, setToastDescription);
    } finally {
      setLoading(false);
    }
  }

  function handlePlaceChanged(setFieldValue, setFieldTouched) {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        setFieldValue("latitude", lat);
        setFieldValue("longitude", lng);
        setMarkerPosition({ lat, lng });
        setCenter({ lat, lng });

        const address = place.formatted_address || "";
        setFieldValue("address", address);
        setFieldTouched("address", true, false);
      }
    }
  }

  const handleMarkerDragEnd = async (event, setFieldValue) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    setFieldValue("latitude", lat);
    setFieldValue("longitude", lng);

    try {
      const address = await getAddressFromCoordinates(lat, lng);
      setFieldValue("address", address);
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const handleViewVideo = async (videoID) => {
    try {
      const { content } = await DownloadVideo(videoID);

      const url = window.URL.createObjectURL(content);

      VideoPreview({
        url,
        onClose: () => {
          window.URL.revokeObjectURL(url);
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSubmitVideo = (videoData, values, setFieldValue) => {
    if (
      !videoData ||
      !videoData[0].file ||
      !videoData[0].title ||
      !videoData[0].duration
    ) {
      console.error("Incomplete video data provided.");
      return;
    }

    const updatedVideos = [...(values.videos || [])];

    const videoBlob = new Blob([videoData[0].file], {
      type: videoData[0].file.type,
    });
    // const videoUrl = URL.createObjectURL(videoBlob);

    const newVideoData = {
      ID: videoData[0].id || `temp-${new Date().getTime()}`, // Use `id` consistently
      title: videoData[0].title,
      file: videoBlob,
      duration: videoData[0].duration,
    };

    console.log(newVideoData);

    updatedVideos.push(newVideoData);

    setFieldValue("videos", updatedVideos);
  };

  const handleSelectExistingVideo = async (
    videoArray,
    values,
    setFieldValue
  ) => {
    const updatedVideos = [...(values.videos || [])];

    for (const video of videoArray) {
      if (!updatedVideos.some((v) => v.id === video.id)) {
        const videoBlob = await DownloadVideo(video.id);
        const videoUrl = URL.createObjectURL(videoBlob.content);

        updatedVideos.push({
          ID: video.id,
          title: video.title,
          url: videoUrl,
          duration: video.duration,
        });
      }
    }

    setFieldValue("videos", updatedVideos);
  };

  const handleRemoveVideo = (videoId, values, setFieldValue) => {
    const updatedVideos = values.videos.filter((video) => video.ID !== videoId);
    setFieldValue("videos", updatedVideos);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const getAddressFromCoordinates = (lat, lng) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          resolve(results[0].formatted_address);
        } else {
          reject(status);
        }
      });
    });
  };

  const FormSection = ({ icon, title, children }) => (
    <div
      style={{
        marginBottom: "20px",
        padding: "20px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>{icon}</div>
        <div
          style={{
            fontSize: "1.75rem",
            fontFamily: "SeagoeUIBold",
          }}
        >
          {title}
        </div>
      </div>
      <div
        className="form-section-content"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {children}
      </div>
    </div>
  );

  async function SaveLocationData(values) {
    try {
      setLoading(true);

      // Prepare devices with associated tags
      const formattedDevices = values.devices.map((deviceId, index) => ({
        device_id: deviceId,
        tags: (values.tags[index] || []).map((tagId) => ({ id: tagId })),
      }));

      // Separate existing and new (temp) videos
      const existingVideos = values.videos.filter(
        (v) => v.ID && !String(v.ID).startsWith("temp-")
      );
      const newVideos = values.videos.filter(
        (v) => !v.ID || String(v.ID).startsWith("temp-")
      );

      // For the initial location update, only include existing videos
      const payload = {
        name: values.name,
        latitude: String(values.latitude),
        longitude: String(values.longitude),
        address: values.address,
        county: values.county,
        city: values.city,
        devices: formattedDevices,
        videos: existingVideos.map((video) => ({ id: video.ID })),
      };

      const jsonPayload = JSON.stringify(payload);
      const response = await SaveLocationInfo(jsonPayload, id);

      if (response.status !== "OK") {
        throw new Error(
          response.error || "Something went wrong, please try again later."
        );
      }

      // Now that the location is updated with existing videos,
      // handle uploading the new (temp) videos.
      // This is similar to what you do in the add scenario.
      const updatedLocation = response.data; // Make sure the response includes the updated location data with ID

      for (const video of newVideos) {
        console.log(video);
        // This matches the logic from your add scenario
        const videoData = new FormData();
        videoData.append("file", video.file);
        videoData.append("title", video.title);
        videoData.append("duration", video.duration);
        // videoData.append("is_landscape", video.is_landscape);
        videoData.append("categories", JSON.stringify([2]));
        videoData.append("location_ids", JSON.stringify([updatedLocation.ID]));

        const uploadResponse = await UploadVideo(videoData);
        if (uploadResponse.status !== "OK") {
          throw new Error(
            uploadResponse.error || "Failed to upload video for location."
          );
        }
      }

      handleStatus(
        "Location and video updated successfully!",
        setToastStatus,
        setToastDescription
      );

      await GetDataInfo(id);
    } catch (error) {
      handleStatus(error.message, setToastStatus, setToastDescription);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    GetDataInfo(id);
  }, []);

  useEffect(() => {
    if (state && state.preview) {
      setPreview(state.preview); // Should log "Hello, World!"
    }
  }, [state]);

  useEffect(() => {
    if (data && data.latitude && data.longitude) {
      const newCenter = {
        lat: parseFloat(data.latitude),
        lng: parseFloat(data.longitude),
      };
      setCenter(newCenter);
      setMarkerPosition(newCenter);
    }
  }, [data]);

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
      {toastStatus && <PopSnackbar message={toastDescription} />}
      <div
        className="form-container"
        style={{ width: "100%", opacity: loading ? 0.5 : 1 }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Formik
            validationSchema={validation}
            initialValues={{
              name: data?.name || "",
              latitude: data?.latitude || "",
              longitude: data?.longitude || "",
              address: data?.address || "",
              county: data?.county || "",
              city: data?.city || "",
              videos: data?.videos || [],
              devices: data?.devices.map((device) => device.device_id) || [],
              tags:
                data?.devices.map((device) =>
                  device.tags.map((tag) => tag.ID)
                ) || [],
            }}
            onSubmit={(values) => SaveLocationData(values)}
          >
            {({ setFieldValue, setFieldTouched, values }) => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: "20px",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <FormSection
                      title="General Location Details"
                      icon={<MapPin size={"1.5rem"} />}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        <Field
                          name="name"
                          label="Location Name"
                          disabled={preview}
                          component={TextFormField}
                          style={{ width: "100%" }}
                          sx={styles.select}
                          placeholder="e.g., Regina Maria"
                        />
                        <Field
                          name="county"
                          label="County"
                          component={TextFormField}
                          sx={styles.select}
                          style={{ width: "100%" }}
                          placeholder="e.g., Bucuresti"
                        />
                        <Field
                          name="city"
                          label="City"
                          component={TextFormField}
                          sx={styles.select}
                          style={{ width: "100%" }}
                          placeholder="e.g., Sector 1"
                        />
                        {isLoaded && (
                          <div style={{ width: "100%" }}>
                            <GoogleAutocomplete
                              onLoad={(autocomplete) =>
                                (autocompleteRef.current = autocomplete)
                              }
                              onPlaceChanged={() =>
                                handlePlaceChanged(
                                  setFieldValue,
                                  setFieldTouched
                                )
                              }
                            >
                              <input
                                name="address"
                                value={values.address}
                                onChange={(e) =>
                                  setFieldValue("address", e.target.value)
                                }
                                placeholder="Enter an address"
                                style={{
                                  width: "100%",
                                  padding: "15px",
                                  borderRadius: "4px",
                                  fontFamily: "SeagoeUI",
                                  border: "none",
                                  fontSize: "1em",
                                  boxSizing: "border-box",
                                  backgroundColor: "#f9f9f9",
                                }}
                              />
                            </GoogleAutocomplete>
                          </div>
                        )}
                        <Field
                          name="latitude"
                          label="Latitude"
                          sx={styles.select}
                          disabled={true}
                          component={TextFormField}
                          style={{ flex: 1 }}
                        />
                        <Field
                          name="longitude"
                          label="Longitude"
                          sx={styles.select}
                          disabled={true}
                          component={TextFormField}
                          style={{ flex: 1 }}
                        />
                      </div>

                      {/* Sublocations Section */}
                    </FormSection>
                    <FormSection
                      title="Assign Devices"
                      icon={<MapPin size={"1.5rem"} />}
                    >
                      <Field
                        name="devices"
                        label="Select Devices"
                        component={MultiSelectFormField}
                        options={deviceData
                          .filter(
                            (device) =>
                              device.location_id === null ||
                              device.location_id === Number(id)
                          )
                          .map((device) => ({
                            value: device.device_id,
                            label: device.name,
                          }))}
                        style={{ width: "100%" }}
                        sx={styles.select}
                      />

                      {/* Device Tags */}
                      {values.devices?.map((deviceId, index) => {
                        const device = deviceData.find(
                          (d) => d.device_id === deviceId
                        );
                        return (
                          <div
                            key={index}
                            style={{
                              marginTop: "20px",
                              padding: "10px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                              borderRadius: "8px",
                              backgroundColor: "white",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "1.25rem",
                                marginBottom: "10px",
                              }}
                            >
                              {device.name}
                            </div>
                            <Field
                              disabled={preview}
                              name={`tags[${index}]`}
                              label="Select Tags For Device"
                              component={MultiSelectFormField}
                              options={tagData?.map((tag) => ({
                                value: tag.ID,
                                label: tag.name,
                              }))}
                              style={{ width: "100%" }}
                              sx={styles.select}
                            />
                          </div>
                        );
                      })}
                    </FormSection>
                    <FormSection
                      title="Assigned Videos"
                      icon={<Video size={"1.5rem"} />}
                    >
                      {/* Render both existing and newly added videos */}
                      {(values.videos || []).length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                            padding: "10px",
                            backgroundColor: "#f9f9f9",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        >
                          {values.videos?.map((video, index) => (
                            <div
                              key={video.id || `temp-${index}`} // Use `id` consistently
                              style={{
                                flex: "1 1 45%",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                padding: "10px",
                                backgroundColor: "white",
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                              }}
                            >
                              <div
                                style={{
                                  flex: "1 1 auto",
                                  display: "flex",
                                  columnGap: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    margin: "0 0 5px 0",
                                    fontSize: "1em",
                                    fontFamily: "SeagoeUI",
                                  }}
                                >
                                  {video.title}.mp4
                                </span>
                                {!preview && (
                                  <>
                                    {!String(video.ID).startsWith("temp-") && (
                                      <>
                                        <Tooltip title={"Edit this video"}>
                                          <Edit2
                                            size={20}
                                            style={{
                                              color: "gray",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              navigate(`/videos/${video.ID}`)
                                            }
                                          />
                                        </Tooltip>
                                        <Tooltip title={"Quick preview"}>
                                          <Eye
                                            size={20}
                                            style={{
                                              color: "gray",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleViewVideo(video.ID)
                                            }
                                          />
                                        </Tooltip>
                                      </>
                                    )}

                                    <Tooltip title={"Remove this video"}>
                                      <MinusSquare
                                        size={20}
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleRemoveVideo(
                                            video.ID,
                                            values,
                                            setFieldValue
                                          )
                                        }
                                      />
                                    </Tooltip>
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {/* Button for adding videos */}
                      <button
                        type="button"
                        className="add-video-button"
                        style={{
                          opacity: preview ? 0.5 : 1,
                        }}
                        disabled={loading || preview}
                        onClick={() => setIsModalOpen(true)}
                      >
                        Add video
                      </button>
                      <AddVideoDialog
                        open={isModalOpen}
                        onClose={handleModalClose}
                        onSubmit={(videoData) =>
                          handleSubmitVideo(videoData, values, setFieldValue)
                        }
                        onSelect={(videoArray) =>
                          handleSelectExistingVideo(
                            videoArray,
                            values,
                            setFieldValue
                          )
                        }
                        instantUpload={false}
                      />
                    </FormSection>
                  </div>

                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {isLoaded ? (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          rowGap: "20px",
                        }}
                      >
                        <GoogleMap
                          mapContainerStyle={{
                            width: "100%",
                            height: "100%",
                            minHeight: "680px",
                            borderRadius: "8px",
                          }}
                          center={center}
                          zoom={15}
                          options={{
                            draggable: !preview,
                            scrollwheel: !preview,
                            disableDoubleClickZoom: preview,
                            gestureHandling: preview ? "none" : "auto",
                          }}
                        >
                          <Marker
                            draggable={!preview}
                            position={markerPosition}
                            onDragEnd={(event) =>
                              !preview &&
                              handleMarkerDragEnd(event, setFieldValue)
                            }
                          />
                        </GoogleMap>
                        {preview ? (
                          <button
                            onClick={() => navigate(-1)}
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "black",
                              padding: "10px 15px",
                              border: 0,
                              borderRadius: "5px",
                              cursor: "pointer",
                              color: "white",
                              fontSize: "1em",
                            }}
                          >
                            Back
                          </button>
                        ) : (
                          <button
                            disabled={loading}
                            className="add-video-button"
                            type="submit"
                          >
                            Save changes
                          </button>
                        )}
                      </div>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingPreview}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
