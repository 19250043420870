import React, { useEffect, useState } from "react";
import { getLoopDetails, submitEditedLoop } from "../../services/loop";
import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Slider,
} from "@mui/material";
import { Film, Repeat } from "react-feather";
import { DownloadVideo, GetAllVideos } from "../../services/video";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "12px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  title: {
    marginBottom: "20px",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  videoList: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    padding: "10px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  listItem: {
    padding: "10px",
    borderBottom: "1px solid #eee",
  },
  videoPreview: {
    marginTop: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
};

export default function EditLoop() {
  const [data, setData] = useState(null); // Store loop data
  const [loading, setLoading] = useState(false);

  const [toastStatus, setToastStatus] = useState(false);
  const [toastDescription, setToastDescription] = useState("");

  const [open, setOpen] = useState(false);
  const [videos, setVideos] = useState([]);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [swapAll, setSwapAll] = useState(false);
  const [selectedVideoDetails, setSelectedVideoDetails] = useState([]); // Store details for selected videos
  const [isLoadingPreview, setIsLoadingPreview] = useState(true);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [newTime, setNewTime] = useState(0);
  const [swapCount, setSwapCount] = useState(1); // Track the number of replacements
  const [playlistLength, setPlaylistLength] = useState(0);

  // Get the loop ID from the URL
  const loopId = window.location.href.split("/").pop();
  const navigator = useNavigate();

  const getRandomIndices = (array, count) => {
    // randomly pick count elements from the array
    const result = [];
    const copy = [...array];
    for (let i = 0; i < count; i++) {
      const randomIndex = Math.floor(Math.random() * copy.length);
      result.push(copy[randomIndex]);
      copy.splice(randomIndex, 1);
    }
    return result;
  };

  const fetchLoopVideos = async () => {
    try {
      setLoading(true);

      const response = await getLoopDetails(loopId);
      const filteredVideos = response.data.videos?.filter(
        (video) => video.video_id !== 999999
      );

      setData({ ...response, videos: filteredVideos });
      // Calculate the total length of the playlist
      calculatePlaylistLength(filteredVideos);
    } catch (error) {
      handleStatus(error.message, setToastStatus, setToastDescription);
    } finally {
      setLoading(false);
    }
  };

  const calculatePlaylistLength = (videos) => {
    const totalDuration = videos.reduce(
      (acc, video) => acc + video.video.exact_duration || video.exact_duration,
      0
    );

    //trim it to 2 decimal places
    setPlaylistLength(totalDuration.toFixed(2));
  };

  const handleSelectChange = async (e) => {
    const videoIds = e.target.value; // Array of selected IDs
    setSelectedVideoId(videoIds);

    try {
      setIsLoadingPreview(true);

      const newVideoDetails = [];

      for (const videoId of videoIds) {
        const existingVideo = selectedVideoDetails.find(
          (video) => video.id === videoId
        );
        if (existingVideo) {
          newVideoDetails.push(existingVideo); // Keep existing details
          continue;
        }

        const video = videos.find((video) => video.ID === videoId);
        const videoBlob = await DownloadVideo(videoId);

        const videoUrl = URL.createObjectURL(videoBlob.content);

        newVideoDetails.push({
          id: videoId,
          title: video.title,
          url: videoUrl,
          duration: video.duration || 0,
          exact_duration: video.exact_duration || 0,
          categories: video.categories || [], // Include categories
          isLandscape: video.is_landscape,
        });
      }

      setSelectedVideoDetails(newVideoDetails);
    } catch (error) {
      console.error("Error downloading videos:", error);
    } finally {
      setIsLoadingPreview(false);
    }
  };

  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  const fetchVideosByCategory = async (videoId) => {
    try {
      const content = await GetAllVideos();
      const filteredVideos = content.data.filter(
        (video) =>
          //exclude the current video
          video.ID !== videoId
      );
      setVideos(filteredVideos);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  const submitVideoOrderHandler = async (forceProceed) => {
    if (!data || !data.videos) {
      console.error("No videos available to submit.");
      return;
    }

    //create an array with data.videos.video.id
    const videoOrder = data.videos.map((videoItem) => videoItem.video.ID);

    try {
      setLoading(true);
      const response = await submitEditedLoop(videoOrder, loopId, forceProceed); // Send the video order to the server
      if (response.status === "success") {
        navigator("/loops");
      } else if (response.status !== "success") {
        setConfirmationDialogOpen(true);
        setNewTime(response.duration);
      }
    } catch (error) {
      handleStatus(error.message, setToastStatus, setToastDescription);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoopVideos();
  }, []);

  const handleSwapClick = (index, videoId) => {
    fetchVideosByCategory(videoId);
    setCurrentVideoIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedVideoId(null);
    setVideos([]);
    setSwapAll(false);
    setSelectedVideoDetails([]);
    setSwapCount(1);
  };

  const handleSubmitSwap = () => {
    if (selectedVideoId && selectedVideoId.length > 0) {
      const selectedVideoIdValue = selectedVideoId[0]; // Get the first element
      const selectedVideo = selectedVideoDetails.find(
        (v) => v.id === selectedVideoIdValue
      );

      if (!selectedVideo) {
        console.error(
          "Selected video not found in the selectedVideoDetails list."
        );
        return;
      }

      setData((prevData) => {
        const updatedVideos = [...prevData.videos];

        const currentVideoId = updatedVideos[currentVideoIndex]?.video?.ID;
        if (swapAll) {
          const newVideos = updatedVideos.map((videoItem) =>
            videoItem.video.ID === currentVideoId // Compare against the current video's id
              ? {
                  ...videoItem,
                  video: {
                    ...selectedVideo,
                    ID: selectedVideo.id, // Ensure ID is set
                    categories: selectedVideo.categories || [], // Include categories or default to an empty array
                  },
                }
              : videoItem
          );

          // Recalculate playlist length after update
          calculatePlaylistLength(newVideos);

          return {
            ...prevData,
            videos: newVideos,
          };
        } else {
          // Get all the indices of the current video
          const currentVideoIndices = updatedVideos
            .map((videoItem, index) =>
              videoItem.video.ID === currentVideoId ? index : null
            )
            .filter((index) => index !== null);

          // Get the random indices to swap
          const randomIndices = getRandomIndices(
            currentVideoIndices,
            swapCount
          );

          // if length is 1, we update just the current video
          if (randomIndices.length === 1) {
            updatedVideos[currentVideoIndex] = {
              ...updatedVideos[currentVideoIndex],
              video: {
                ...selectedVideo,
                ID: selectedVideo.id, // Ensure ID is set
                categories: selectedVideo.categories || [], // Include categories or default to an empty array
              },
            };
          } else {
            randomIndices.forEach((index) => {
              updatedVideos[index] = {
                ...updatedVideos[index],
                video: {
                  ...selectedVideo,
                  ID: selectedVideo.id, // Ensure ID is set
                  categories: selectedVideo.categories || [], // Include categories or default to an empty array
                },
              };
            });
          }

          // Recalculate playlist length after update
          calculatePlaylistLength(updatedVideos);

          return { ...prevData, videos: updatedVideos };
        }
      });

      handleClose();
    } else {
      console.error("No video selected or invalid selectedVideoId.");
    }
  };

  const mapParentId = (parentId) => {
    if ([1, 4, 5, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(parentId)) {
      return 1;
    } else if (parentId === null || parentId === "") {
      return 2;
    } else if ([3, 6, 7, 17].includes(parentId)) {
      return 3;
    } else {
      return parentId; // Default case if needed
    }
  };

  const onDragEnd = (result) => {
    // If dropped outside the list, do nothing
    if (!result.destination) return;

    // Reorder the videos array
    const reorderedVideos = Array.from(data.videos);
    const [removed] = reorderedVideos.splice(result.source.index, 1);
    reorderedVideos.splice(result.destination.index, 0, removed);

    // Update the state with the new order
    setData((prevData) => ({
      ...prevData,
      videos: reorderedVideos,
    }));

    // Explicitly blur the focused element to prevent it from staying selected
    if (document.activeElement) {
      document.activeElement.blur();
    }
  };

  console.log(data);

  return (
    <div style={styles.container}>
      <Typography style={styles.title}>
        Length of videos:{" "}
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            {Math.floor(playlistLength / 60)}' {Math.floor(playlistLength % 60)}
            ''{" "}
          </>
        )}
      </Typography>
      <button
        className="add-video-button"
        style={{ width: "10vw", marginBottom: "0.5rem" }}
        onClick={() => submitVideoOrderHandler(false)}
        disabled={loading}
      >
        Submit Video Order
      </button>
      <Divider style={{ marginBottom: "20px" }} />
      {loading ? (
        <CircularProgress size={24} />
      ) : data ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="videoList">
            {(provided) => (
              <List
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={styles.videoList}
              >
                {data.videos?.map((videoItem, index) => {
                  const uniqueParentIds = new Set(
                    videoItem.video.categories.map((category) =>
                      mapParentId(category.parent_id)
                    )
                  );

                  // Generate a unique ID for draggable items
                  const uniqueDraggableId = `${videoItem.video.ID}-${index}`;

                  return (
                    <Draggable
                      key={uniqueDraggableId}
                      draggableId={uniqueDraggableId}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            ...styles.listItem,
                            background: snapshot.isDragging
                              ? "rgb(235,235,235)"
                              : "inherit",
                            transition: snapshot.isDragging
                              ? "none"
                              : "transform 0.2s ease",
                          }}
                        >
                          <ListItemIcon>
                            <Film fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primary={videoItem.video?.title}
                            secondary={
                              <div>
                                <span>
                                  Duration: {videoItem.video?.duration} seconds
                                </span>
                                <span style={{ marginLeft: "10px" }}>
                                  Categories:{" "}
                                  {Array.from(uniqueParentIds).map(
                                    (parentId, index) => (
                                      <span key={index}>
                                        {(() => {
                                          let categoryName;
                                          switch ((parentId % 3) + 1) {
                                            case 1:
                                              categoryName =
                                                "Campanii publicitare";
                                              break;
                                            case 2:
                                              categoryName =
                                                "Spoturi de locatie";
                                              break;
                                            case 3:
                                              categoryName =
                                                "Continut informativ";
                                              break;
                                            default:
                                              categoryName = "";
                                          }
                                          return categoryName;
                                        })()}{" "}
                                        {index < uniqueParentIds.size - 1
                                          ? ", "
                                          : ""}
                                      </span>
                                    )
                                  )}
                                </span>
                              </div>
                            }
                          />
                          <Tooltip title="Swap Video">
                            <Repeat
                              fontSize="small"
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              onClick={() =>
                                handleSwapClick(index, videoItem.video.ID)
                              }
                            />
                          </Tooltip>
                        </ListItem>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Typography>No data available</Typography>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <Autocomplete
            options={videos}
            getOptionLabel={(video) => video.title}
            onChange={(event, newValue) => {
              setSelectedVideoId(newValue?.ID || null);
              handleSelectChange({
                target: { value: newValue ? [newValue.ID] : [] },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Video"
                placeholder="Type to search"
                fullWidth
              />
            )}
          />
          {selectedVideoDetails.map((video, index) => (
            <div key={video.id} style={{ marginBottom: "16px" }}>
              <h4 style={{ fontFamily: "SeagoeUIBold" }}>{video.title}</h4>
              <video
                key={`select-${video.url}`}
                width="100%"
                controls
                style={{
                  marginBottom: 8,
                  borderRadius: 4,
                  boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                }}
              >
                <source src={video.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <p>Duration: {video.duration} seconds</p>
            </div>
          ))}
          <Typography variant="subtitle1" style={{ marginTop: "16px" }}>
            Select the number of replacements:
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Slider
              value={swapCount}
              onChange={(e, newValue) => {
                setSwapCount(newValue);
                setSwapAll(
                  newValue ===
                    data?.videos.filter(
                      (videoItem) =>
                        videoItem.video.ID ===
                        data?.videos[currentVideoIndex]?.video?.ID
                    ).length
                );
              }}
              valueLabelDisplay="auto"
              step={1}
              min={1}
              max={
                data?.videos.filter(
                  (videoItem) =>
                    videoItem.video.ID ===
                    data?.videos[currentVideoIndex]?.video?.ID
                ).length
              }
              style={{ flexGrow: 1, marginRight: "16px" }}
            />
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ whiteSpace: "nowrap" }}
            >
              Selected: {swapCount} /{" "}
              {
                data?.videos.filter(
                  (videoItem) =>
                    videoItem.video.ID ===
                    data?.videos[currentVideoIndex]?.video?.ID
                ).length
              }
            </Typography>
          </div>

          <FormControlLabel
            control={
              <Checkbox
                checked={swapAll}
                onChange={(e) => {
                  setSwapAll(e.target.checked);
                  setSwapCount(
                    e.target.checked
                      ? data?.videos.filter(
                          (videoItem) =>
                            videoItem.video.ID ===
                            data?.videos[currentVideoIndex]?.video?.ID
                        ).length
                      : 1
                  );
                }}
              />
            }
            label="Swap all appearances"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitSwap}
            color="primary"
            disabled={!selectedVideoId || isLoadingPreview}
          >
            Swap
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      >
        <DialogContent>
          <Typography>
            The new duration of the playlist exceeds the max limit. The new
            duration of the playlist will be{" "}
            <span style={{ fontWeight: "bold" }}>
              {Math.floor(newTime / 60)}' {Math.floor(newTime % 60)}''
            </span>
            . Do you want to continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmationDialogOpen(false)}
            color="default"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setConfirmationDialogOpen(false);
              submitVideoOrderHandler(true);
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {toastStatus && <div className="toast">{toastDescription}</div>}
    </div>
  );
}
