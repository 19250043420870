import React, { useState, useEffect, useRef } from "react";
import PopSnackbar from "../../../components/snackbar";
import {
  DownloadVideo,
  GetVideoInfo,
  UpdateVideo,
} from "../../../services/video";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import StyledDropzone from "../../../components/dropzone/dropzone";
import VideoGeneralTab from "../tabs/general";
import CategoriesVideoTab from "../tabs/categories";
import TagVideoTab from "../tabs/tags";
import { GetTags } from "../../../services/tag";

import { CircularProgress } from "@mui/material";
import { GetAllPartners } from "../../../services/partners";
import { GetAllLocations } from "../../../services/locations";
import { GetAllCategories } from "../../../services/category";
import { getFinalCategoriesWithFirstAndLast } from "../../../components/getfinalcategoriesfirstandlast";

const EditVideo = () => {
  const [preview, setPreview] = useState(false);

  // General video states
  const [videoFile, setVideoFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDuration, setVideoDuration] = useState("");
  const [isLandscape, setIsLandscape] = useState(true);

  // Tabs and notifications
  const [activeTab, setActiveTab] = useState("General Information");
  const [toastStatus, setToastStatus] = useState(false);
  const [toastDescription, setToastDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Categories and locations states
  const [categories, setCategories] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [partners, setPartners] = useState([]);
  const [availablePartners, setAvailablePartners] = useState([]);
  const [groups, setGroups] = useState([
    { id: 0, categoryValues: [], locationValues: [], selectedPartner: null },
  ]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoryGroups, setSelectedCategoryGroups] = useState([]);

  // Tags
  const [tags, setTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);

  // Loaders
  const [loading, setLoading] = useState(true);
  const [isTagsLoading, setIsTagsLoading] = useState(false);

  // Additional flags
  const [isSpecialCategory, setIsSpecialCategory] = useState(false);
  const [allPartners, setAllPartners] = useState(false);
  const [allLocations, setAllLocations] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const videoUrlRef = useRef(null);

  // Fetch categories, locations, partners, and tags
  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await GetAllCategories();
      setAvailableCategories(response.data);
      return response.data;
    } catch (error) {
      handleStatus(
        "Failed to fetch categories.",
        setToastStatus,
        setToastDescription
      );
      return null;
    } finally {
      setLoading(false);
    }
  };

  const fetchLocations = async () => {
    try {
      setLoading(true);
      const response = await GetAllLocations();
      setAvailableLocations(response.data);
    } catch (error) {
      handleStatus(
        "Failed to fetch locations.",
        setToastStatus,
        setToastDescription
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchPartners = async () => {
    try {
      setLoading(true);
      const response = await GetAllPartners();
      setAvailablePartners(response.data);
    } catch (error) {
      handleStatus(
        "Failed to fetch partners.",
        setToastStatus,
        setToastDescription
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchTags = async () => {
    try {
      setIsTagsLoading(true);
      const response = await GetTags();
      setAvailableTags(response.data);
    } catch (error) {
      handleStatus(
        "Failed to fetch tags.",
        setToastStatus,
        setToastDescription
      );
    } finally {
      setIsTagsLoading(false);
    }
  };

  const findCategoryPath = (categories, targetId, path = []) => {
    for (const category of categories) {
      if (category.ID === targetId) {
        return [...path, category.ID];
      }
      if (category.subcategories?.length) {
        const subPath = findCategoryPath(category.subcategories, targetId, [
          ...path,
          category.ID,
        ]);
        if (subPath) return subPath;
      }
    }
    return null;
  };

  const fetchVideoInfo = async (categoriesData) => {
    try {
      setLoading(true);

      const content = await GetVideoInfo(id);
      if (content.status !== "OK") {
        throw new Error(content.error || "Something went wrong.");
      }

      const data = content.data;

      setVideoTitle(data.title);
      setVideoDuration(data.duration);
      setIsLandscape(data.is_landscape);

      // Preprocess available categories to include displayName
      const finalCategories =
        getFinalCategoriesWithFirstAndLast(categoriesData);

      // Map selected categories to include displayName
      const updatedCategories = data.categories.map((selectedCategory) => {
        const matchedCategory = finalCategories.find(
          (cat) => cat.ID === selectedCategory.ID
        );
        return matchedCategory || selectedCategory; // Use original if no match found
      });

      setCategories(updatedCategories);

      const assignedCategoryPaths = updatedCategories.map((category) =>
        findCategoryPath(categoriesData, category.ID)
      );

      setSelectedCategoryGroups(assignedCategoryPaths);

      const isSpecialCategory = updatedCategories.some(
        (category) => category.ID === 2
      );
      setIsSpecialCategory(isSpecialCategory);

      let allPartnerIds = [];
      let allLocationIds = [];

      if (isSpecialCategory) {
        const partnersData = await fetchPartners();
        const locationsData = await fetchLocations();

        allPartnerIds = partnersData?.map((partner) => partner.ID);
        allLocationIds = locationsData?.map((location) => location.ID);
      }

      const initialGroups = assignedCategoryPaths.map(
        (categoryValues, index) => {
          const lastCategoryId = categoryValues[categoryValues?.length - 1];
          if (lastCategoryId === 2) {
            const selectedPartners = Array.from(
              new Set(data.locations?.map((loc) => loc.partner_id))
            );

            const allPartnersSelected =
              selectedPartners?.length === allPartnerIds?.length;

            return {
              id: index,
              categoryValues,
              locationValues: allPartnersSelected
                ? allLocationIds
                : data.locations?.map((loc) => loc.ID) || [],
              selectedPartner: selectedPartners,
            };
          }

          return {
            id: index,
            categoryValues,
            locationValues: [],
            selectedPartner: [],
          };
        }
      );

      setGroups(initialGroups);

      setTags(data.tags);
      setLocations(data.locations);
      const uniquePartners = Array.from(
        new Map(
          data.locations
            .filter((location) => location.partner) // Ensure location has a partner
            .map((location) => [location.partner.ID, location.partner])
        ).values()
      );

      // Update the partners state
      setPartners(uniquePartners);

      const { content: blob, filename } = await DownloadVideo(id);
      const file = new File([blob], filename, { type: blob.type });
      setVideoFile(file);

      if (videoUrlRef.current) {
        URL.revokeObjectURL(videoUrlRef.current);
      }
      const videoUrl = URL.createObjectURL(blob);
      videoUrlRef.current = videoUrl;
      setVideoPreview(videoUrl);
    } catch (error) {
      handleStatus(error.message, setToastStatus, setToastDescription);
    } finally {
      setLoading(false);
    }
  };

  // Video processing
  const processVideo = (file) => {
    if (videoUrlRef.current) URL.revokeObjectURL(videoUrlRef.current);
    const videoUrl = URL.createObjectURL(file);
    videoUrlRef.current = videoUrl;
    setVideoFile(file);
    setVideoPreview(videoUrl);
    setVideoTitle(file.name.split(".").slice(0, -1).join("."));
    const videoElement = document.createElement("video");
    videoElement.src = videoUrl;
    videoElement.onloadedmetadata = () =>
      setVideoDuration(Math.floor(videoElement.duration));
  };

  const handleFileChange = (event) => {
    const file = Array.isArray(event) ? event[0] : event.target?.files?.[0];
    if (file) processVideo(file);
  };

  // Submit video
  const handleSubmit = async () => {
    const videoData = new FormData();
    videoData.append("file", videoFile);
    videoData.append("title", videoTitle);
    videoData.append("duration", videoDuration);
    videoData.append("is_landscape", isLandscape);

    const selectedLocationIds = locations.map((location) => location.ID);

    const finalCategories = isSpecialCategory
      ? [...new Set([...categories, 2])]
      : categories;

    if (finalCategories.length === 0) {
      return handleStatus(
        "Please select at least one category.",
        setToastStatus,
        setToastDescription
      );
    }

    videoData.append(
      "categories",
      JSON.stringify(finalCategories.map((cat) => cat.ID))
    );
    videoData.append("location_ids", JSON.stringify(selectedLocationIds));
    videoData.append("tag_ids", JSON.stringify(tags.map((tag) => tag.ID)));

    try {
      setIsSubmitting(true);
      const result = await UpdateVideo(
        id,
        videoData,
        allLocations,
        allPartners
      );
      if (result.status !== "OK") throw new Error(result.error);
      handleStatus(
        "Video uploaded successfully!",
        setToastStatus,
        setToastDescription
      );
      navigate(-1);
    } catch (error) {
      handleStatus(error.message, setToastStatus, setToastDescription);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Utility for status notifications
  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  const initialize = async () => {
    const categoriesData = await fetchCategories();
    if (categoriesData) {
      await fetchPartners();
      await fetchLocations();
      await fetchTags();
      await fetchVideoInfo(categoriesData);
    }
  };

  useEffect(() => {
    initialize();
    return () => {
      if (videoUrlRef.current) URL.revokeObjectURL(videoUrlRef.current);
    };
  }, []);

  useEffect(() => {
    if (state && state.preview) setPreview(state.preview);
  }, [state]);

  return (
    <div style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          opacity: isSubmitting ? 0.5 : 1,
          padding: "25px",
          borderRadius: "16px",
          boxShadow: "0 3px 12px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
      >
        <div className="add-video-container left-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "20px",
              borderBottom: "2px solid #ddd",
              gap: 50,
            }}
          >
            {["General Information", "Categories & Locations", "Tags"].map(
              (tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  style={{
                    flex: 1,
                    padding: "10px",
                    fontSize: "0.96rem",
                    fontFamily: activeTab === tab ? "SeagoeUIBold" : "SeagoeUI",
                    color: activeTab === tab ? "#000" : "#555",
                    background: "none",
                    border: "none",
                    borderBottom: activeTab === tab ? "2px solid #000" : "none",
                    cursor: "pointer",
                  }}
                >
                  {tab}
                </button>
              )
            )}
          </div>

          {activeTab === "General Information" && (
            <VideoGeneralTab
              videoTitle={videoTitle}
              setVideoTitle={setVideoTitle}
              videoFile={videoFile}
              videoDuration={videoDuration}
              isLandscape={isLandscape}
              setIsLandscape={setIsLandscape}
              isSubmitting={isSubmitting}
              preview={preview}
            />
          )}
          {activeTab === "Categories & Locations" && (
            <CategoriesVideoTab
              categories={categories}
              setCategories={setCategories}
              availableCategories={availableCategories}
              locations={locations}
              setLocations={setLocations}
              availableLocations={availableLocations}
              partners={partners}
              setPartners={setPartners}
              availablePartners={availablePartners}
              setAllLocations={setAllLocations}
              setAllPartners={setAllPartners}
              preview={preview}
              loading={loading}
            />
          )}
          {activeTab === "Tags" && (
            <TagVideoTab
              tags={tags}
              setTags={setTags}
              isTagsLoading={isTagsLoading}
              availableTags={availableTags}
              handleAddTag={setTags}
              preview={preview}
            />
          )}
          {toastStatus && <PopSnackbar message={toastDescription} />}
        </div>
        <div className="add-video-container right-container">
          <h1 className="add-video-title left">Upload your video</h1>
          {!preview && (
            <div className="upload-area">
              <StyledDropzone
                multiple={false}
                onDrop={handleFileChange}
                title={"Drag 'n' drop or click to select a video"}
                accept="video/*"
              />
            </div>
          )}
          {videoPreview && (
            <div className="video-preview">
              <video key={videoPreview} width="100%" controls>
                <source src={videoPreview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
          <button
            onClick={preview ? () => navigate(-1) : handleSubmit}
            disabled={
              !videoFile ||
              !videoTitle ||
              !videoDuration ||
              isSubmitting ||
              (categories.some((c) => c.ID === 2) &&
                (partners.length === 0 ||
                  (!allPartners && locations.length === 0)))
            }
            className="submit-button"
          >
            {isSubmitting ? (
              <CircularProgress size={24} />
            ) : preview ? (
              "Back"
            ) : (
              "Update Video"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditVideo;
