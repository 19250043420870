import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { TextFormField } from "../../components/textform/TextFormField.tsx";
import { json, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { CreateLocation } from "../../services/locations.js";
import {
  GoogleMap,
  useLoadScript,
  Autocomplete as GoogleAutocomplete,
  Marker,
} from "@react-google-maps/api";
import { MapPin, Video, MinusSquare } from "react-feather";
import AddVideoDialog from "../../components/modal/videodialog.js";
import PopSnackbar from "../../components/snackbar/index.js";
import {
  AddVideoToLocation,
  GetVideosByCategory,
  UploadVideo,
} from "../../services/video.js";
import MultiSelectFormField from "../../components/multiselectform/MultiSelectFormField.tsx";
import { GetAllDevices } from "../../services/devices.js";
import { GetTags } from "../../services/tag.js";

const libraries = ["places"];

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
    "& .MuiInputLabel-root": { color: "#555" },
    "&.Mui-disabled .MuiOutlinedInput-root": { backgroundColor: "#f0f0f0" },
  },
};

export const validation = yup.object().shape({
  location_name: yup.string().required("Location name is required"),
});

export default function AddLocation() {
  const [toastStatus, setToastStatus] = useState(false);
  const [toastDescription, setToastDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [center, setCenter] = useState({ lat: 44.4268, lng: 26.1025 });
  const [markerPosition, setMarkerPosition] = useState(center);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const navigate = useNavigate();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [deviceData, setDeviceData] = useState([]);
  const [tagData, setTagData] = useState([]);

  let partnerId = new URLSearchParams(window.location.search).get("partner");
  if (partnerId == null) {
    partnerId = "";
  }

  const autocompleteRef = useRef(null);

  async function GetData() {
    try {
      setLoadingLocations(true);

      const responseDevices = await GetAllDevices();
      if (responseDevices.status !== "OK") {
        throw new Error(
          responseDevices.error ||
            "Something went wrong, please try again later."
        );
      }

      setDeviceData(responseDevices.data);

      const responseTags = await GetTags();
      if (responseTags.status !== "OK") {
        throw new Error(
          responseTags.error || "Something went wrong, please try again later."
        );
      }

      setTagData(responseTags.data);
    } catch (error) {
      handleStatus(error.message, setToastStatus, setToastDescription);
    } finally {
      setLoadingLocations(false);
    }
  }

  function handlePlaceChanged(setFieldValue, setFieldTouched) {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        setFieldValue("latitude", lat);
        setFieldValue("longitude", lng);
        setMarkerPosition({ lat, lng });
        setCenter({ lat, lng });

        const address = place.formatted_address || "";
        setFieldValue("address", address);
        setFieldTouched("address", true, false);
      }
    }
  }

  const handleMarkerDragEnd = async (event, setFieldValue) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    setFieldValue("latitude", lat);
    setFieldValue("longitude", lng);

    try {
      const address = await getAddressFromCoordinates(lat, lng);
      setFieldValue("address", address);
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmitVideo = (videoArray) => {
    setSelectedVideos((prev) => [...prev, ...videoArray]);
  };

  const handleSelectExistingVideo = (videoArray) => {
    setSelectedVideos((prev) => [...prev, ...videoArray]);
  };

  const handleRemoveVideo = (index) => {
    setSelectedVideos((prev) => prev.filter((_, i) => i !== index));
  };

  const GetInitialVideos = async () => {
    try {
      const response = await GetVideosByCategory(0);
      if (response.status !== "OK") {
        throw new Error(
          response.error || "Something went wrong, please try again later."
        );
      }

      const selectedAllPartnersVideos = response.data.filter(
        (video) => video.selected_all_partners
      );

      const selectedAllLocationsVideos = response.data.filter(
        (video) =>
          video.selected_all_locations &&
          video.locations.some(
            (location) => location.partner_id.toString() === partnerId
          )
      );

      const combinedVideos = [
        ...selectedAllPartnersVideos,
        ...selectedAllLocationsVideos,
      ];

      const uniqueCombinedVideos = combinedVideos.filter(
        (video, index, self) =>
          index === self.findIndex((v) => v.id === video.id)
      );

      setSelectedVideos(uniqueCombinedVideos);
    } catch (error) {
      handleStatus(error.message, setToastStatus, setToastDescription);
    }
  };

  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);

      const devices = values.devices.map((deviceId, index) => {
        const device = deviceData.find((d) => d.device_id === deviceId);
        const tags = values.tags?.[index]
          ?.map((tagId) => {
            const tag = tagData.find((t) => t.ID === tagId);
            return tag ? { ID: tag.ID, name: tag.name } : null;
          })
          .filter(Boolean); // Remove any null values

        return {
          device_id: device?.device_id,
          name: device?.name,
          tags: tags || [], // Ensure tags is an array even if empty
        };
      });

      const data = {
        name: values.location_name,
        latitude: values.latitude.toString(),
        longitude: values.longitude.toString(),
        address: values.address,
        county: values.county,
        city: values.city,
        devices,
      };

      const jsonData = JSON.stringify(data);

      const locationResponse = await CreateLocation(jsonData, partnerId);

      if (locationResponse.status !== "OK") {
        throw new Error(locationResponse.error || "Failed to create location.");
      }

      const createdLocation = locationResponse.data;

      // Link selected videos to the location
      for (const video of selectedVideos) {
        console.log(video);
        if (video.id || video.ID) {
          // Link existing video
          const linkResponse = await AddVideoToLocation(
            video.id || video.ID,
            createdLocation.ID
          );
          if (linkResponse.status !== "OK") {
            throw new Error(
              linkResponse.error || "Failed to link video to location."
            );
          }
        } else if (video.file) {
          // Upload and link new video
          const videoData = new FormData();
          videoData.append("file", video.file);
          videoData.append("title", video.title);
          videoData.append("duration", video.duration);
          videoData.append("is_landscape", video.is_landscape);
          videoData.append("categories", JSON.stringify([2]));
          videoData.append(
            "location_ids",
            JSON.stringify([createdLocation.ID])
          );
          const uploadResponse = await UploadVideo(videoData);
          if (uploadResponse.status !== "OK") {
            throw new Error(
              uploadResponse.error || "Failed to upload video for location."
            );
          }
        }
      }

      handleStatus(
        "The location has been successfully created.",
        setToastStatus,
        setToastDescription
      );
      navigate(-1);
    } catch (error) {
      handleStatus(error.message, setToastStatus, setToastDescription);
    } finally {
      setLoading(false);
    }
  };

  const getAddressFromCoordinates = (lat, lng) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          resolve(results[0].formatted_address);
        } else {
          reject(status);
        }
      });
    });
  };

  const FormSection = ({ icon, title, children }) => (
    <div
      style={{
        marginBottom: "20px",
        padding: "20px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>{icon}</div>
        <div
          style={{
            fontSize: "1.75rem",
            fontFamily: "SeagoeUIBold",
          }}
        >
          {title}
        </div>
      </div>
      <div
        className="form-section-content"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {children}
      </div>
    </div>
  );

  useEffect(() => {
    if (!loadingLocations) {
      GetData();
      GetInitialVideos();
    }
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
      {toastStatus && <PopSnackbar message={toastDescription} />}
      <div
        className="form-container"
        style={{ width: "100%", opacity: loading ? 0.5 : 1 }}
      >
        <Formik
          validationSchema={validation}
          initialValues={{
            location_name: "",
            latitude: "",
            longitude: "",
            address: "",
            devices: [], // Directly link devices to the location
          }}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue, setFieldTouched, values }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <FormSection
                    title="General Location Details"
                    icon={<MapPin size={"1.5rem"} />}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                      }}
                    >
                      <>
                        <Field
                          name="location_name"
                          label="Location Name"
                          component={TextFormField}
                          sx={styles.select}
                          style={{ width: "100%" }}
                          placeholder="e.g., Regina Maria"
                        />
                        <Field
                          name="county"
                          label="County"
                          component={TextFormField}
                          sx={styles.select}
                          style={{ width: "100%" }}
                          placeholder="e.g., Bucuresti"
                        />
                        <Field
                          name="city"
                          label="City"
                          component={TextFormField}
                          sx={styles.select}
                          style={{ width: "100%" }}
                          placeholder="e.g., Sector 1"
                        />
                        {isLoaded && (
                          <div style={{ width: "100%" }}>
                            <GoogleAutocomplete
                              onLoad={(autocomplete) =>
                                (autocompleteRef.current = autocomplete)
                              }
                              onPlaceChanged={() =>
                                handlePlaceChanged(
                                  setFieldValue,
                                  setFieldTouched
                                )
                              }
                            >
                              <input
                                name="address"
                                value={values.address}
                                onChange={(e) =>
                                  setFieldValue("address", e.target.value)
                                }
                                placeholder="Enter an address"
                                style={{
                                  width: "100%",
                                  padding: "15px",
                                  borderRadius: "4px",
                                  fontFamily: "SeagoeUI",
                                  border: "none",
                                  fontSize: "1em",
                                  boxSizing: "border-box",
                                  backgroundColor: "#f9f9f9",
                                }}
                              />
                            </GoogleAutocomplete>
                          </div>
                        )}
                        <Field
                          name="latitude"
                          label="Latitude"
                          sx={styles.select}
                          disabled={true}
                          component={TextFormField}
                          style={{ flex: 1 }}
                        />
                        <Field
                          name="longitude"
                          label="Longitude"
                          sx={styles.select}
                          disabled={true}
                          component={TextFormField}
                          style={{ flex: 1 }}
                        />
                      </>
                    </div>
                  </FormSection>

                  {/* Device Assignment */}
                  <FormSection
                    title="Assign Devices"
                    icon={<MapPin size={"1.5rem"} />}
                  >
                    <Field
                      name="devices"
                      label="Select Devices"
                      component={MultiSelectFormField}
                      options={deviceData
                        .filter((device) => device.location_id === null)
                        .map((device) => ({
                          value: device.device_id,
                          label: device.name,
                        }))}
                      style={{ width: "100%" }}
                      sx={styles.select}
                    />

                    {/* Device Tags */}
                    {values.devices.map((deviceId, index) => {
                      const device = deviceData.find(
                        (d) => d.device_id === deviceId
                      );

                      return (
                        <div
                          key={deviceId}
                          style={{
                            marginTop: "20px",
                            padding: "10px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            borderRadius: "8px",
                            backgroundColor: "white",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "1.25rem",
                              marginBottom: "10px",
                            }}
                          >
                            {device.name}
                          </div>
                          <Field
                            name={`tags[${index}]`}
                            label="Select Tags For Device"
                            component={MultiSelectFormField}
                            options={tagData.map((tag) => ({
                              value: tag.ID,
                              label: tag.name,
                            }))}
                            style={{ width: "100%" }}
                            sx={styles.select}
                          />
                        </div>
                      );
                    })}
                  </FormSection>
                  <FormSection
                    title="Video Configuration"
                    icon={<Video size={"1.5rem"} />}
                  >
                    {selectedVideos.map((video, index) => (
                      <div
                        key={index}
                        style={{
                          marginBottom: "10px",
                          padding: "10px",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          fontSize: "1rem",
                          fontFamily: "SeagoeUI",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <span style={{ fontFamily: "SeagoeUIBold" }}>
                            {video.title}
                          </span>
                          <span style={{ fontFamily: "SeagoeUI" }}>.mp4</span>
                        </span>
                        <MinusSquare
                          style={{
                            cursor: "pointer",
                            transition: "transform 0.2s",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.transform = "scale(1.1)")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                          onClick={() => handleRemoveVideo(index)}
                        />
                      </div>
                    ))}
                    <button
                      type="button"
                      className="add-video-button"
                      disabled={loading}
                      onClick={() => setIsModalOpen(true)}
                    >
                      Add videos
                    </button>
                    <AddVideoDialog
                      open={isModalOpen}
                      onClose={handleModalClose}
                      onSubmit={handleSubmitVideo}
                      onSelect={handleSelectExistingVideo}
                      instantUpload={false}
                    />
                  </FormSection>
                </div>

                {/* Map Section */}
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {isLoaded ? (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        rowGap: "20px",
                      }}
                    >
                      <GoogleMap
                        mapContainerStyle={{
                          width: "100%",
                          height: "100%",
                          minHeight: "680px",
                          borderRadius: "8px",
                        }}
                        center={center}
                        zoom={15}
                      >
                        <Marker
                          draggable
                          position={markerPosition}
                          onDragEnd={(event) =>
                            handleMarkerDragEnd(event, setFieldValue)
                          }
                        />
                      </GoogleMap>
                      <button
                        disabled={loading}
                        className="add-video-button"
                        type="submit"
                        style={{ marginTop: "20px" }}
                      >
                        Submit
                      </button>
                    </div>
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
